

.husband {
    width: 15vw;
    height: auto;
    display: flex;

    border: 1px solid rgb(143, 31, 31);
    border-radius: 25px;
    box-shadow: 2px 2px;
}

.radioButton {
    border: none;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    background-color: black;
    color: white;

}

.submitButton {
    border: none;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    background-color: black;
    color: white;

}

.husbandContainer {
    min-height: 50vw;
    width: 50vw;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(71, 71, 71);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    align-items: center;
  } 

  .response {
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
  }

  .buttonSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

/* .active {
    opacity: 0;
} */