  
.App{
  background-image: linear-gradient(to top, #feada6 0%, #f5efef 100%);
  display: flex;
  justify-content: center;
}


.titleimg {
  height: 20vw;
  width: auto;
  display: flex;
  justify-content: center;
  background-color: black;
}

footer {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: xx-large;
}

.submitButton {
  border: none;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;

}